import React, { useState } from 'react';
import { X } from 'lucide-react';
import { ProcessStage } from '../types/assessment';

type StageFormProps = {
  onSubmit: (stage: Omit<ProcessStage, 'id'>) => void;
  onClose: () => void;
};

export default function StageForm({ onSubmit, onClose }: StageFormProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [employeeUnderstanding, setEmployeeUnderstanding] = useState('');
  const [formalProcedure, setFormalProcedure] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      name,
      description,
      employeeUnderstanding,
      formalProcedure,
    });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Add Process Stage</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-6">
          <div className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Stage Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={2}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label htmlFor="employeeUnderstanding" className="block text-sm font-medium text-gray-700">
                Employee Understanding
              </label>
              <textarea
                id="employeeUnderstanding"
                value={employeeUnderstanding}
                onChange={(e) => setEmployeeUnderstanding(e.target.value)}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Record employee's understanding of this stage..."
                required
              />
            </div>
            <div>
              <label htmlFor="formalProcedure" className="block text-sm font-medium text-gray-700">
                Formal Procedure
              </label>
              <textarea
                id="formalProcedure"
                value={formalProcedure}
                onChange={(e) => setFormalProcedure(e.target.value)}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Document the formal written procedure for this stage..."
                required
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
            >
              Add Stage
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}