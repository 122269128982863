import React from 'react';
import { useAssessmentStore } from '../store/assessmentStore';
import { AssessmentInfo } from '../types/assessment';

export default function AssessmentForm() {
  const { assessmentInfo, setAssessmentInfo } = useAssessmentStore();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAssessmentInfo({
      ...assessmentInfo,
      [name]: value,
    } as AssessmentInfo);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
      <h2 className="text-xl font-semibold mb-6">Datos Generales de la Evaluación</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="evaluatorName" className="block text-sm font-medium text-gray-700 mb-1">
            Nombre del Evaluador
          </label>
          <input
            type="text"
            id="evaluatorName"
            name="evaluatorName"
            value={assessmentInfo?.evaluatorName || ''}
            onChange={handleChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
            Fecha de Evaluación
          </label>
          <input
            type="date"
            id="date"
            name="date"
            value={assessmentInfo?.date || ''}
            onChange={handleChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
            Ubicación/Sitio
          </label>
          <input
            type="text"
            id="location"
            name="location"
            value={assessmentInfo?.location || ''}
            onChange={handleChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label htmlFor="area" className="block text-sm font-medium text-gray-700 mb-1">
            Unidad/Área Evaluada
          </label>
          <input
            type="text"
            id="area"
            name="area"
            value={assessmentInfo?.area || ''}
            onChange={handleChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required
          />
        </div>
      </div>
    </div>
  );
}