import React, { useState } from 'react';
import { GapAnalysis } from '../services/openai';
import { AlertTriangle, CheckCircle2, Loader2, ChevronDown, ChevronUp } from 'lucide-react';

interface GapAnalysisProps {
  gaps: GapAnalysis[] | null;
  loading: boolean;
  error: string | null;
}

export default function GapAnalysisComponent({ gaps, loading, error }: GapAnalysisProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-6">
        <Loader2 className="h-6 w-6 animate-spin text-blue-600" />
        <span className="ml-2 text-gray-600">Analizando brechas...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 rounded-lg">
        <div className="flex items-center">
          <AlertTriangle className="h-5 w-5 text-red-500" />
          <span className="ml-2 text-red-700">Error al analizar brechas: {error}</span>
        </div>
      </div>
    );
  }

  if (!gaps || gaps.length === 0) {
    return (
      <div className="p-4 bg-green-50 rounded-lg">
        <div className="flex items-center">
          <CheckCircle2 className="h-5 w-5 text-green-500" />
          <span className="ml-2 text-green-700">No se identificaron brechas significativas</span>
        </div>
      </div>
    );
  }

  return (
    <div className="border rounded-lg bg-white">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-4 py-3 flex items-center justify-between text-left hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-center">
          <span className="font-medium text-gray-900">
            Análisis de Brechas ({gaps.length} {gaps.length === 1 ? 'brecha' : 'brechas'})
          </span>
        </div>
        {isExpanded ? (
          <ChevronUp className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-500" />
        )}
      </button>

      {isExpanded && (
        <div className="p-4 border-t space-y-4">
          {gaps.map((gap, index) => (
            <div 
              key={index} 
              className={`p-4 rounded-lg border ${
                index !== gaps.length - 1 ? 'mb-4' : ''
              }`}
            >
              <div className="space-y-3">
                <div>
                  <h5 className="text-sm font-medium text-gray-900">Descripción de la Brecha</h5>
                  <p className="mt-1 text-sm text-gray-600">{gap.description}</p>
                </div>
                <div>
                  <h5 className="text-sm font-medium text-gray-900">Categoría</h5>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    {gap.category}
                  </span>
                </div>
                <div>
                  <h5 className="text-sm font-medium text-gray-900">Recomendaciones</h5>
                  <p className="mt-1 text-sm text-gray-600">{gap.recommendations}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}