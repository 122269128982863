import React, { useState, useRef, useEffect } from 'react';
import { Pencil, Check, X } from 'lucide-react';
import { useAssessmentStore } from '../store/assessmentStore';

export default function ProcessHeader() {
  const { currentProcess, updateCurrentProcess } = useAssessmentStore();
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(currentProcess?.name || '');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const handleStartEdit = () => {
    setEditedName(currentProcess?.name || '');
    setIsEditing(true);
  };

  const handleSave = () => {
    if (editedName.trim() && currentProcess) {
      updateCurrentProcess({ name: editedName.trim() });
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedName(currentProcess?.name || '');
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      handleCancel();
    }
  };

  if (!currentProcess) return null;

  return (
    <div className="flex items-center space-x-2">
      {isEditing ? (
        <div className="flex items-center space-x-2">
          <input
            ref={inputRef}
            type="text"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            onKeyDown={handleKeyDown}
            className="px-2 py-1 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Nombre del proceso"
          />
          <button
            onClick={handleSave}
            className="p-1 text-green-600 hover:text-green-700"
            title="Guardar"
          >
            <Check className="h-5 w-5" />
          </button>
          <button
            onClick={handleCancel}
            className="p-1 text-red-600 hover:text-red-700"
            title="Cancelar"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      ) : (
        <div className="flex items-center space-x-2">
          <h1 className="text-2xl font-bold text-gray-900">{currentProcess.name}</h1>
          <button
            onClick={handleStartEdit}
            className="p-1 text-gray-400 hover:text-gray-600"
            title="Editar nombre"
          >
            <Pencil className="h-4 w-4" />
          </button>
        </div>
      )}
    </div>
  );
}