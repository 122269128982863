import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Process, ProcessStage, PillarAssessment, AssessmentInfo, SavedEvaluation } from '../types/assessment';
import { processTemplates } from '../data/processTemplates';

interface AssessmentState {
  processes: Process[];
  currentProcess: Process | null;
  assessmentInfo: AssessmentInfo | null;
  savedEvaluations: SavedEvaluation[];
  addProcess: (process: Process) => void;
  deleteProcess: (processId: string) => void;
  setCurrentProcess: (process: Process | null) => void;
  updateCurrentProcess: (updates: Partial<Process>) => void;
  setAssessmentInfo: (info: AssessmentInfo) => void;
  updateStage: (stageId: string, updates: Partial<ProcessStage>) => void;
  updatePillar: (stageId: string, pillar: keyof Process['pillars'], assessment: PillarAssessment) => void;
  useTemplate: (templateId: string) => void;
  saveEvaluation: () => void;
  cloneEvaluation: (evaluationId: string) => void;
}

export const useAssessmentStore = create<AssessmentState>()(
  persist(
    (set) => ({
      processes: [],
      currentProcess: null,
      assessmentInfo: null,
      savedEvaluations: [],
      addProcess: (process) =>
        set((state) => ({ processes: [...state.processes, process] })),
      deleteProcess: (processId) =>
        set((state) => ({
          processes: state.processes.filter((p) => p.id !== processId),
          currentProcess: state.currentProcess?.id === processId ? null : state.currentProcess,
        })),
      setCurrentProcess: (process) => set({ currentProcess: process }),
      updateCurrentProcess: (updates) =>
        set((state) => {
          if (!state.currentProcess) return state;
          const updatedProcess = { ...state.currentProcess, ...updates };
          return {
            currentProcess: updatedProcess,
            processes: state.processes.map((p) =>
              p.id === updatedProcess.id ? updatedProcess : p
            ),
          };
        }),
      setAssessmentInfo: (info) => set({ assessmentInfo: info }),
      updateStage: (stageId, updates) =>
        set((state) => {
          if (!state.currentProcess) return state;
          const updatedStages = state.currentProcess.stages.map((stage) =>
            stage.id === stageId ? { ...stage, ...updates } : stage
          );
          return {
            currentProcess: {
              ...state.currentProcess,
              stages: updatedStages,
            },
          };
        }),
      updatePillar: (stageId, pillar, assessment) =>
        set((state) => {
          if (!state.currentProcess) return state;
          const updatedPillars = {
            ...state.currentProcess.pillars,
            [stageId]: {
              ...state.currentProcess.pillars[stageId],
              [pillar]: assessment,
            },
          };
          return {
            currentProcess: {
              ...state.currentProcess,
              pillars: updatedPillars,
            },
          };
        }),
      useTemplate: (templateId) => {
        const template = processTemplates.find(t => t.id === templateId);
        if (template) {
          const newProcess = {
            ...template,
            id: crypto.randomUUID(),
          };
          set((state) => ({
            processes: [...state.processes, newProcess],
            currentProcess: newProcess,
          }));
        }
      },
      saveEvaluation: () =>
        set((state) => {
          if (!state.currentProcess || !state.assessmentInfo) return state;

          const savedEvaluation: SavedEvaluation = {
            id: crypto.randomUUID(),
            processName: state.currentProcess.name,
            assessmentInfo: state.assessmentInfo,
            stages: state.currentProcess.stages,
            pillars: state.currentProcess.pillars,
            completedAt: new Date().toISOString(),
          };

          return {
            savedEvaluations: [...state.savedEvaluations, savedEvaluation],
          };
        }),
      cloneEvaluation: (evaluationId) =>
        set((state) => {
          const evaluation = state.savedEvaluations.find(e => e.id === evaluationId);
          if (!evaluation) return state;

          const stageIdMap = new Map<string, string>();
          const newStages = evaluation.stages.map(stage => {
            const newId = crypto.randomUUID();
            stageIdMap.set(stage.id, newId);
            return { ...stage, id: newId };
          });

          const newPillars: Record<string, any> = {};
          Object.entries(evaluation.pillars).forEach(([oldStageId, pillarData]) => {
            const newStageId = stageIdMap.get(oldStageId);
            if (newStageId) {
              newPillars[newStageId] = pillarData;
            }
          });

          const newProcess: Process = {
            id: crypto.randomUUID(),
            name: `${evaluation.processName} (Copia)`,
            stages: newStages,
            pillars: newPillars,
          };

          const newAssessmentInfo: AssessmentInfo = {
            ...evaluation.assessmentInfo,
            date: new Date().toISOString().split('T')[0],
          };

          return {
            processes: [...state.processes, newProcess],
            currentProcess: newProcess,
            assessmentInfo: newAssessmentInfo,
          };
        }),
    }),
    {
      name: 'assessment-store',
      partialize: (state) => ({
        savedEvaluations: state.savedEvaluations,
      }),
    }
  )
);