import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useAssessmentStore } from '../store/assessmentStore';
import { Process } from '../types/assessment';

type ProcessFormProps = {
  onClose: () => void;
};

export default function ProcessForm({ onClose }: ProcessFormProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const addProcess = useAssessmentStore((state) => state.addProcess);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newProcess: Process = {
      id: crypto.randomUUID(),
      name,
      description,
      stages: [],
      pillars: {},
    };
    addProcess(newProcess);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">New Process</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-4">
          <div className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Process Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
            >
              Create Process
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}