import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { SavedEvaluation } from '../types/assessment';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Helvetica'
  },
  header: {
    marginBottom: 20
  },
  title: {
    fontSize: 24,
    marginBottom: 10
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 20,
    color: '#666'
  },
  section: {
    marginBottom: 15
  },
  sectionTitle: {
    fontSize: 16,
    marginBottom: 10,
    color: '#333'
  },
  infoGrid: {
    flexDirection: 'row',
    marginBottom: 15
  },
  infoColumn: {
    flex: 1
  },
  infoItem: {
    marginBottom: 8
  },
  label: {
    fontSize: 10,
    color: '#666',
    marginBottom: 2
  },
  value: {
    fontSize: 12
  },
  stage: {
    marginBottom: 15,
    padding: 10,
    backgroundColor: '#f8f9fa'
  },
  stageHeader: {
    fontSize: 14,
    marginBottom: 8
  },
  stageDescription: {
    fontSize: 12,
    marginBottom: 8,
    color: '#666'
  },
  pillarGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 8
  },
  pillar: {
    width: '50%',
    marginBottom: 6
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    fontSize: 10,
    color: '#666',
    textAlign: 'center'
  }
});

interface PDFDocumentProps {
  evaluation: SavedEvaluation;
}

const PDFDocument: React.FC<PDFDocumentProps> = ({ evaluation }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>{evaluation.processName}</Text>
        <Text style={styles.subtitle}>Evaluación de Disciplina Operativa</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Información General</Text>
        <View style={styles.infoGrid}>
          <View style={styles.infoColumn}>
            <View style={styles.infoItem}>
              <Text style={styles.label}>Evaluador</Text>
              <Text style={styles.value}>{evaluation.assessmentInfo.evaluatorName}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.label}>Fecha</Text>
              <Text style={styles.value}>
                {format(new Date(evaluation.assessmentInfo.date), 'PPP', { locale: es })}
              </Text>
            </View>
          </View>
          <View style={styles.infoColumn}>
            <View style={styles.infoItem}>
              <Text style={styles.label}>Ubicación</Text>
              <Text style={styles.value}>{evaluation.assessmentInfo.location}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.label}>Área</Text>
              <Text style={styles.value}>{evaluation.assessmentInfo.area}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Etapas del Proceso</Text>
        {evaluation.stages.map((stage) => (
          <View key={stage.id} style={styles.stage}>
            <Text style={styles.stageHeader}>{stage.name}</Text>
            <Text style={styles.stageDescription}>{stage.description}</Text>

            <View style={styles.infoGrid}>
              <View style={styles.infoColumn}>
                <Text style={styles.label}>Entendimiento del Empleado</Text>
                <Text style={styles.value}>{stage.employeeUnderstanding}</Text>
              </View>
              <View style={styles.infoColumn}>
                <Text style={styles.label}>Procedimiento Formal</Text>
                <Text style={styles.value}>{stage.formalProcedure}</Text>
              </View>
            </View>

            <View style={styles.pillarGrid}>
              {Object.entries(evaluation.pillars[stage.id] || {}).map(([pillar, assessment]) => (
                <View key={pillar} style={styles.pillar}>
                  <Text style={styles.label}>{pillar}</Text>
                  <Text style={styles.value}>
                    {assessment.rating === 'efectivo' ? 'Efectivo' : 'No efectivo'}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>

      <Text style={styles.footer}>
        Documento generado el {format(new Date(), 'PPP', { locale: es })}
      </Text>
    </Page>
  </Document>
);

export default PDFDocument;