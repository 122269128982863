import React, { useMemo } from 'react';
import { useAssessmentStore } from '../../store/assessmentStore';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';
import { CheckCircle2, XCircle, AlertCircle } from 'lucide-react';

const processColors = [
  '#3b82f6', // blue
  '#22c55e', // green
  '#f59e0b', // amber
  '#6366f1', // indigo
  '#ec4899', // pink
  '#8b5cf6', // purple
  '#14b8a6', // teal
  '#f43f5e', // rose
];

const pillarNames = {
  humanIntegrity: 'Integridad Humana',
  formalityDeficiency: 'Formalidad',
  teamworkDeficiency: 'Trabajo en Equipo',
  competencyDeficiency: 'Competencia',
  questioningAttitudeDeficiency: 'Actitud Cuestionadora',
};

export default function AnalyticsDashboard() {
  const { savedEvaluations } = useAssessmentStore();

  const { processStats, totalPillarStats, pillarStats } = useMemo(() => {
    const stats = new Map();
    const pillarTotals = {
      humanIntegrity: { total: 0, effective: 0 },
      formalityDeficiency: { total: 0, effective: 0 },
      teamworkDeficiency: { total: 0, effective: 0 },
      competencyDeficiency: { total: 0, effective: 0 },
      questioningAttitudeDeficiency: { total: 0, effective: 0 },
    };
    
    savedEvaluations.forEach(evaluation => {
      if (!stats.has(evaluation.processName)) {
        stats.set(evaluation.processName, {
          name: evaluation.processName,
          count: 0,
          pillars: {
            effective: 0,
            ineffective: 0,
            notEvaluated: 0,
          },
        });
      }
      
      const processStats = stats.get(evaluation.processName);
      processStats.count++;
      
      Object.entries(evaluation.pillars).forEach(([stageId, stagePillars]) => {
        Object.entries(stagePillars).forEach(([pillar, assessment]) => {
          pillarTotals[pillar].total++;
          if (assessment.rating === 'efectivo') {
            pillarTotals[pillar].effective++;
            processStats.pillars.effective++;
          } else if (assessment.rating === 'no-efectivo') {
            processStats.pillars.ineffective++;
          } else {
            processStats.pillars.notEvaluated++;
          }
        });
      });
    });

    const processStatsArray = Array.from(stats.values());
    const totalStats = processStatsArray.reduce((acc, process) => {
      acc.effective += process.pillars.effective;
      acc.ineffective += process.pillars.ineffective;
      acc.notEvaluated += process.pillars.notEvaluated;
      return acc;
    }, { effective: 0, ineffective: 0, notEvaluated: 0 });

    // Calculate effectiveness percentages for radar chart
    const pillarEffectiveness = Object.entries(pillarTotals).map(([key, value]) => ({
      pillar: pillarNames[key as keyof typeof pillarNames],
      effectiveness: value.total > 0 ? (value.effective / value.total) * 100 : 0,
      ineffectiveness: value.total > 0 ? ((value.total - value.effective) / value.total) * 100 : 0,
    }));

    return {
      processStats: processStatsArray,
      totalPillarStats: totalStats,
      pillarStats: pillarEffectiveness,
    };
  }, [savedEvaluations]);

  if (savedEvaluations.length === 0) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-sm p-6 text-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            No hay datos suficientes
          </h3>
          <p className="text-gray-500">
            Complete algunas evaluaciones para ver análisis detallados
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Panel de Análisis
        </h2>
        <p className="text-gray-500">
          Resumen de evaluaciones y estado de pilares por proceso
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Procesos Evaluados y Cantidad de Evaluaciones */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-6">
            Evaluaciones por Proceso
          </h3>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={processStats}
                margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  tick={{ fontSize: 12 }}
                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill="#3b82f6" name="Evaluaciones">
                  {processStats.map((entry, index) => (
                    <Cell key={index} fill={processColors[index % processColors.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Radar Chart for Pillar Effectiveness */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-6">
            Análisis de Deficiencias por Pilar
          </h3>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart cx="50%" cy="50%" outerRadius="80%" data={pillarStats}>
                <PolarGrid stroke="#e5e7eb" />
                <PolarAngleAxis
                  dataKey="pillar"
                  tick={{ fill: '#4b5563', fontSize: 12 }}
                />
                <PolarRadiusAxis
                  angle={30}
                  domain={[0, 100]}
                  tick={{ fill: '#4b5563', fontSize: 10 }}
                />
                <Radar
                  name="Efectividad"
                  dataKey="effectiveness"
                  stroke="#22c55e"
                  fill="#22c55e"
                  fillOpacity={0.3}
                />
                <Radar
                  name="Deficiencia"
                  dataKey="ineffectiveness"
                  stroke="#ef4444"
                  fill="#ef4444"
                  fillOpacity={0.3}
                />
                <Tooltip formatter={(value) => `${value.toFixed(1)}%`} />
              </RadarChart>
            </ResponsiveContainer>
          </div>
          <div className="mt-4 flex justify-center space-x-6">
            <div className="flex items-center">
              <div className="w-3 h-3 bg-green-500 opacity-30 mr-2"></div>
              <span className="text-sm text-gray-600">Efectividad</span>
            </div>
            <div className="flex items-center">
              <div className="w-3 h-3 bg-red-500 opacity-30 mr-2"></div>
              <span className="text-sm text-gray-600">Deficiencia</span>
            </div>
          </div>
        </div>

        {/* Estado Acumulado de Pilares */}
        <div className="bg-white rounded-lg shadow-sm p-6 lg:col-span-2">
          <h3 className="text-lg font-medium text-gray-900 mb-6">
            Estado General de Pilares
          </h3>
          <div className="space-y-6">
            <div className="grid grid-cols-3 gap-4">
              <div className="bg-green-50 rounded-lg p-4 text-center">
                <CheckCircle2 className="h-8 w-8 text-green-500 mx-auto mb-2" />
                <div className="text-2xl font-bold text-green-700">
                  {totalPillarStats.effective}
                </div>
                <div className="text-sm text-green-600">Efectivos</div>
              </div>
              <div className="bg-red-50 rounded-lg p-4 text-center">
                <XCircle className="h-8 w-8 text-red-500 mx-auto mb-2" />
                <div className="text-2xl font-bold text-red-700">
                  {totalPillarStats.ineffective}
                </div>
                <div className="text-sm text-red-600">No Efectivos</div>
              </div>
              <div className="bg-gray-50 rounded-lg p-4 text-center">
                <AlertCircle className="h-8 w-8 text-gray-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-gray-700">
                  {totalPillarStats.notEvaluated}
                </div>
                <div className="text-sm text-gray-600">No Evaluados</div>
              </div>
            </div>

            <div className="mt-8">
              <h4 className="text-sm font-medium text-gray-700 mb-4">
                Desglose por Proceso
              </h4>
              <div className="space-y-4">
                {processStats.map((process, index) => (
                  <div key={process.name} className="border rounded-lg p-4">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center">
                        <div 
                          className="w-3 h-3 rounded-full mr-2"
                          style={{ backgroundColor: processColors[index % processColors.length] }}
                        />
                        <h5 className="font-medium text-gray-900">{process.name}</h5>
                      </div>
                      <span className="text-sm text-gray-500">
                        {process.count} evaluaciones
                      </span>
                    </div>
                    <div className="flex space-x-4">
                      <div className="flex items-center">
                        <CheckCircle2 className="h-4 w-4 text-green-500 mr-1" />
                        <span className="text-sm text-gray-600">
                          {process.pillars.effective}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <XCircle className="h-4 w-4 text-red-500 mr-1" />
                        <span className="text-sm text-gray-600">
                          {process.pillars.ineffective}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <AlertCircle className="h-4 w-4 text-gray-400 mr-1" />
                        <span className="text-sm text-gray-600">
                          {process.pillars.notEvaluated}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}