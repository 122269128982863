import OpenAI from 'openai';
import { ProcessStage } from '../types/assessment';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export interface GapAnalysis {
  description: string;
  category: string;
  recommendations: string;
}

export async function analyzeStageGaps(stage: ProcessStage): Promise<GapAnalysis[]> {
  const prompt = `Eres un Especialista en Disciplina Operacional con experiencia en identificar y analizar brechas entre lo que los empleados entienden y lo que los procedimientos realmente requieren. Tu tarea es realizar una evaluación detallada, enfocándote en identificar si las brechas están relacionadas con Formalidad, Competencia, Trabajo en Equipo, Confianza o Actitud Cuestionadora.

Etapa: ${stage.name}
Entendimiento del Empleado: ${stage.employeeUnderstanding}
Procedimiento Formal: ${stage.formalProcedure}

Para cada brecha, proporciona:
1. Descripción de la Brecha: Articula claramente la discrepancia entre lo que el empleado entiende y lo que establece el procedimiento.
2. Análisis por Categoría: Determina cuál de las cinco categorías (Formalidad, Competencia, Trabajo en Equipo, Confianza o Actitud Cuestionadora) es más probable que contribuya a la brecha y explica por qué.
3. Recomendaciones Especializadas: Sugiere pasos concretos para abordar y cerrar la brecha identificada, adaptados a la categoría específica.

Formatea la respuesta como JSON con la siguiente estructura:
{
  "gaps": [
    {
      "description": "Descripción clara de la brecha",
      "category": "Una de las cinco categorías",
      "recommendations": "Pasos concretos para abordar la brecha"
    }
  ]
}`;

  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: "Eres un Especialista experto en Disciplina Operacional. Responde únicamente con JSON válido."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      temperature: 0.7,
    });

    const content = response.choices[0].message.content;
    if (!content) throw new Error("No se recibió respuesta de OpenAI");

    const analysis = JSON.parse(content);
    return analysis.gaps;
  } catch (error) {
    console.error('Error analizando brechas:', error);
    throw error;
  }
}