import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical, ChevronDown, ChevronUp, Search } from 'lucide-react';
import { ProcessStage } from '../types/assessment';
import PillarAssessment from './PillarAssessment';
import GapAnalysis from './GapAnalysis';
import { useAssessmentStore } from '../store/assessmentStore';
import { analyzeStageGaps, GapAnalysis as GapAnalysisType } from '../services/openai';

interface SortableStageProps {
  stage: ProcessStage;
}

export function SortableStage({ stage }: SortableStageProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: stage.id });
  const { currentProcess, updateStage, updatePillar } = useAssessmentStore();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handlePillarUpdate = (pillar: any, rating: any, observations: string) => {
    updatePillar(stage.id, pillar, { rating, observations });
  };

  const handleAnalyzeGaps = async () => {
    if (!stage.employeeUnderstanding || !stage.formalProcedure) {
      setError('Se requiere completar tanto el entendimiento del empleado como el procedimiento formal para el análisis');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const analysisResults = await analyzeStageGaps(stage);
      updateStage(stage.id, { ...stage, gapAnalysis: analysisResults });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error al analizar brechas');
    } finally {
      setLoading(false);
    }
  };

  const canAnalyze = Boolean(stage.employeeUnderstanding && stage.formalProcedure);

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="bg-white rounded-lg shadow-sm border border-gray-200"
    >
      <div className="p-4">
        <div className="flex items-start gap-4">
          <button
            className="mt-1 text-gray-400 hover:text-gray-600 cursor-grab active:cursor-grabbing"
            {...attributes}
            {...listeners}
          >
            <GripVertical className="h-5 w-5" />
          </button>
          
          <div className="flex-1">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-medium text-lg">{stage.name}</h3>
                <p className="text-gray-600 mt-1">{stage.description}</p>
              </div>
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-gray-400 hover:text-gray-600"
              >
                {isExpanded ? (
                  <ChevronUp className="h-5 w-5" />
                ) : (
                  <ChevronDown className="h-5 w-5" />
                )}
              </button>
            </div>
            
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div>
                <h4 className="text-sm font-medium text-gray-700 mb-2">Entendimiento del Empleado</h4>
                <p className="text-sm text-gray-600">{stage.employeeUnderstanding || 'No registrado aún'}</p>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-700 mb-2">Procedimiento Formal</h4>
                <p className="text-sm text-gray-600">{stage.formalProcedure || 'No definido aún'}</p>
              </div>
            </div>

            <div className="mt-4">
              <button
                onClick={handleAnalyzeGaps}
                disabled={!canAnalyze || loading}
                className={`inline-flex items-center px-3 py-1.5 rounded-md text-sm font-medium ${
                  canAnalyze && !loading
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                }`}
              >
                <Search className="h-4 w-4 mr-2" />
                Analizar Brechas
              </button>
            </div>

            {(loading || error || stage.gapAnalysis) && (
              <div className="mt-4">
                <GapAnalysis gaps={stage.gapAnalysis} loading={loading} error={error} />
              </div>
            )}
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="border-t border-gray-200 p-4">
          <h4 className="text-lg font-medium mb-4">Evaluación de Pilares</h4>
          <PillarAssessment
            stagePillars={currentProcess?.pillars[stage.id] || {}}
            onUpdate={handlePillarUpdate}
          />
        </div>
      )}
    </div>
  );
}