import React from 'react';
import { CheckCircle2, XCircle, AlertCircle } from 'lucide-react';
import { useAssessmentStore } from '../store/assessmentStore';
import { ProcessStage, PillarRating } from '../types/assessment';

const pillarNames = {
  humanIntegrity: 'Integridad Humana',
  formalityDeficiency: 'Formalidad',
  teamworkDeficiency: 'Trabajo en Equipo',
  competencyDeficiency: 'Competencia',
  questioningAttitudeDeficiency: 'Actitud Cuestionadora',
};

export default function ProcessSummary() {
  const currentProcess = useAssessmentStore((state) => state.currentProcess);

  if (!currentProcess || currentProcess.stages.length === 0) {
    return null;
  }

  const getPillarIcon = (rating: PillarRating | undefined) => {
    if (!rating) return <AlertCircle className="h-5 w-5 text-gray-300" />;
    return rating === 'efectivo' 
      ? <CheckCircle2 className="h-5 w-5 text-green-500" />
      : <XCircle className="h-5 w-5 text-red-500" />;
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mt-8">
      <h2 className="text-xl font-semibold mb-6">Resumen de Evaluación</h2>
      
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Etapa
              </th>
              {Object.entries(pillarNames).map(([key, name]) => (
                <th 
                  key={key}
                  className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentProcess.stages.map((stage: ProcessStage) => (
              <tr key={stage.id} className="hover:bg-gray-50">
                <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {stage.name}
                </td>
                {Object.keys(pillarNames).map((pillarKey) => (
                  <td key={pillarKey} className="px-4 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      {getPillarIcon(currentProcess.pillars[stage.id]?.[pillarKey]?.rating)}
                      <span className="ml-2 text-sm text-gray-500">
                        {currentProcess.pillars[stage.id]?.[pillarKey]?.rating
                          ? currentProcess.pillars[stage.id][pillarKey].rating === 'efectivo'
                            ? 'Efectivo'
                            : 'No Efectivo'
                          : 'No evaluado'}
                      </span>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6 border-t pt-4">
        <h3 className="text-sm font-medium text-gray-700 mb-2">Leyenda</h3>
        <div className="flex space-x-6">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500" />
            <span className="ml-2 text-sm text-gray-600">Efectivo</span>
          </div>
          <div className="flex items-center">
            <XCircle className="h-5 w-5 text-red-500" />
            <span className="ml-2 text-sm text-gray-600">No Efectivo</span>
          </div>
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 text-gray-300" />
            <span className="ml-2 text-sm text-gray-600">No Evaluado</span>
          </div>
        </div>
      </div>
    </div>
  );
}