import React, { useState } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Plus, Save } from 'lucide-react';
import { SortableStage } from './SortableStage';
import StageForm from './StageForm';
import AssessmentForm from './AssessmentForm';
import ProcessSummary from './ProcessSummary';
import { useAssessmentStore } from '../store/assessmentStore';
import { ProcessStage } from '../types/assessment';

export default function ProcessStages() {
  const [showStageForm, setShowStageForm] = useState(false);
  const { currentProcess, updateStage, assessmentInfo, saveEvaluation } = useAssessmentStore();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  if (!currentProcess) return null;

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      const oldIndex = currentProcess.stages.findIndex((stage) => stage.id === active.id);
      const newIndex = currentProcess.stages.findIndex((stage) => stage.id === over.id);
      
      const newStages = arrayMove(currentProcess.stages, oldIndex, newIndex);
      updateStage(active.id, { ...currentProcess.stages[oldIndex] });
    }
  };

  const handleAddStage = (stageData: Omit<ProcessStage, 'id'>) => {
    const newStage: ProcessStage = {
      ...stageData,
      id: crypto.randomUUID(),
    };
    currentProcess.stages.push(newStage);
    updateStage(newStage.id, newStage);
  };

  const canSaveEvaluation = Boolean(
    assessmentInfo?.evaluatorName &&
    assessmentInfo?.date &&
    assessmentInfo?.location &&
    assessmentInfo?.area &&
    currentProcess.stages.length > 0
  );

  const handleSaveEvaluation = () => {
    saveEvaluation();
    alert('Evaluación guardada exitosamente');
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <AssessmentForm />
      
      <ProcessSummary />

      <div className="mt-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Etapas del Proceso</h2>
          <div className="flex space-x-4">
            <button
              onClick={handleSaveEvaluation}
              disabled={!canSaveEvaluation}
              className={`inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium
                ${canSaveEvaluation
                  ? 'text-white bg-green-600 hover:bg-green-700'
                  : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                }`}
            >
              <Save className="h-5 w-5 mr-2" />
              Guardar Evaluación
            </button>
            <button
              onClick={() => setShowStageForm(true)}
              className="btn-primary"
            >
              <Plus className="h-5 w-5 mr-2" />
              Agregar Etapa
            </button>
          </div>
        </div>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={currentProcess.stages}
            strategy={verticalListSortingStrategy}
          >
            <div className="space-y-4">
              {currentProcess.stages.map((stage) => (
                <SortableStage key={stage.id} stage={stage} />
              ))}
            </div>
          </SortableContext>
        </DndContext>
        
        {currentProcess.stages.length === 0 && (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <p className="text-gray-500">No hay etapas definidas. Agregue la primera etapa para comenzar.</p>
          </div>
        )}

        {showStageForm && (
          <StageForm
            onSubmit={handleAddStage}
            onClose={() => setShowStageForm(false)}
          />
        )}
      </div>
    </div>
  );
}