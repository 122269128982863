import { Process } from '../types/assessment';

export const processTemplates: Process[] = [
  {
    id: 'ptw',
    name: 'Permiso de Trabajo (PTW)',
    description: 'Procedimiento que regula la autorización de trabajos potencialmente peligrosos, asegurando que se identifican los riesgos y se implementan las medidas de control antes de iniciar la tarea.',
    stages: [],
    pillars: {}
  },
  {
    id: 'loto',
    name: 'Aislamiento de Energías Peligrosas (LOTO)',
    description: 'Sistema para bloquear y etiquetar fuentes de energía peligrosas antes de realizar trabajos de mantenimiento o reparación, para prevenir liberaciones no controladas.',
    stages: [],
    pillars: {}
  },
  {
    id: 'moc',
    name: 'Gestión del Cambio (MOC)',
    description: 'Proceso estructurado para evaluar, aprobar e implementar cambios en procesos, equipos, procedimientos o personal, asegurando que no introduzcan nuevos riesgos.',
    stages: [],
    pillars: {}
  },
  {
    id: 'work-height',
    name: 'Trabajo en Altura',
    description: 'Procedimientos para proteger a los trabajadores que realizan actividades a alturas elevadas, implementando equipos de protección contra caídas y evaluaciones de riesgos.',
    stages: [],
    pillars: {}
  },
  {
    id: 'confined-spaces',
    name: 'Entradas a Espacios Confinados',
    description: 'Gestión de actividades en espacios con acceso limitado y condiciones peligrosas, incluyendo atmósferas deficientes en oxígeno, presencia de gases tóxicos o riesgos de atrapamiento.',
    stages: [],
    pillars: {}
  },
  {
    id: 'contractor-control',
    name: 'Control de Contratistas',
    description: 'Supervisión y aseguramiento de que los contratistas cumplen con los estándares de seguridad, capacitación, y procedimientos específicos de la instalación.',
    stages: [],
    pillars: {}
  },
  {
    id: 'emergency-response',
    name: 'Plan de Respuesta a Emergencias',
    description: 'Conjunto de protocolos diseñados para mitigar y responder a incidentes críticos como derrames, incendios, explosiones o desastres naturales.',
    stages: [],
    pillars: {}
  },
  {
    id: 'maintenance',
    name: 'Mantenimiento Preventivo y Correctivo',
    description: 'Programas para garantizar que los equipos funcionen de manera segura y eficiente, reduciendo el riesgo de fallas inesperadas.',
    stages: [],
    pillars: {}
  }
];