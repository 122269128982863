import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './lib/firebase';
import ProcessSelection from './components/ProcessSelection';
import ProcessStages from './components/ProcessStages';
import HistoricalEvaluations from './components/HistoricalEvaluations';
import AnalyticsDashboard from './components/analytics/AnalyticsDashboard';
import ProcessHeader from './components/ProcessHeader';
import LandingPage from './components/LandingPage';
import { useAssessmentStore } from './store/assessmentStore';
import { useAuthStore } from './store/authStore';
import AuthModal from './components/auth/AuthModal';
import UserMenu from './components/auth/UserMenu';

const App = () => {
  const { currentProcess, setCurrentProcess } = useAssessmentStore();
  const { user, setUser } = useAuthStore();
  const [activeView, setActiveView] = React.useState<'landing' | 'processes' | 'history' | 'analytics'>('landing');
  const [showAuth, setShowAuth] = useState(false);
  const [authMode, setAuthMode] = useState<'signin' | 'signup'>('signin');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, [setUser]);

  return (
    <div className="min-h-screen bg-gray-50">
      {activeView === 'landing' ? (
        <LandingPage onStart={() => setActiveView('processes')} />
      ) : (
        <>
          <header className="bg-white shadow-sm">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between items-center py-4">
                <div className="flex items-center space-x-4">
                  {(currentProcess || activeView !== 'processes') && (
                    <button
                      onClick={() => {
                        setCurrentProcess(null);
                        setActiveView('processes');
                      }}
                      className="inline-flex items-center text-gray-600 hover:text-gray-900"
                    >
                      <ArrowLeft className="h-5 w-5 mr-2" />
                      <span className="text-sm">Volver</span>
                    </button>
                  )}
                  {currentProcess ? (
                    <ProcessHeader />
                  ) : (
                    <div>
                      <h1 className="text-2xl font-bold text-gray-900">
                        {activeView === 'analytics'
                          ? 'Análisis'
                          : activeView === 'history'
                          ? 'Historial de Evaluaciones'
                          : 'OD+ Assessment Tool'}
                      </h1>
                      {activeView === 'processes' && (
                        <p className="text-sm text-gray-500 mt-1">
                          Mantener el sentido de vulnerabilidad requiere disciplina
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex items-center space-x-6">
                  {!currentProcess && (
                    <nav className="flex space-x-4">
                      <button
                        onClick={() => setActiveView('processes')}
                        className={`text-sm ${
                          activeView === 'processes'
                            ? 'text-accent'
                            : 'text-gray-500 hover:text-gray-900'
                        }`}
                      >
                        Procesos
                      </button>
                      <button
                        onClick={() => setActiveView('history')}
                        className={`text-sm ${
                          activeView === 'history'
                            ? 'text-accent'
                            : 'text-gray-500 hover:text-gray-900'
                        }`}
                      >
                        Historial
                      </button>
                      <button
                        onClick={() => setActiveView('analytics')}
                        className={`text-sm ${
                          activeView === 'analytics'
                            ? 'text-accent'
                            : 'text-gray-500 hover:text-gray-900'
                        }`}
                      >
                        Análisis
                      </button>
                    </nav>
                  )}
                  {user ? (
                    <UserMenu />
                  ) : (
                    <div className="flex space-x-4">
                      <button
                        onClick={() => {
                          setAuthMode('signin');
                          setShowAuth(true);
                        }}
                        className="text-sm text-gray-500 hover:text-gray-900"
                      >
                        Iniciar sesión
                      </button>
                      <button
                        onClick={() => {
                          setAuthMode('signup');
                          setShowAuth(true);
                        }}
                        className="btn-primary"
                      >
                        Crear cuenta
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </header>

          <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {currentProcess ? (
              <ProcessStages />
            ) : activeView === 'analytics' ? (
              <AnalyticsDashboard />
            ) : activeView === 'history' ? (
              <HistoricalEvaluations />
            ) : (
              <ProcessSelection />
            )}
          </main>

          <AuthModal
            isOpen={showAuth}
            onClose={() => setShowAuth(false)}
            mode={authMode}
          />
        </>
      )}
    </div>
  );
};

export default App;