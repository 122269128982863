import React, { useState } from 'react';
import { SavedEvaluation } from '../types/assessment';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { 
  X, User, MapPin, Calendar, Building, 
  CheckCircle2, XCircle, ChevronDown, ChevronUp,
  AlertTriangle, Copy, FileDown
} from 'lucide-react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDocument from './PDFDocument';
import GapAnalysis from './GapAnalysis';

interface EvaluationDetailProps {
  evaluation: SavedEvaluation;
  onClose: () => void;
}

export default function EvaluationDetail({ evaluation, onClose }: EvaluationDetailProps) {
  const [expandedStages, setExpandedStages] = useState<Record<string, boolean>>({});

  const toggleStage = (stageId: string) => {
    setExpandedStages(prev => ({
      ...prev,
      [stageId]: !prev[stageId]
    }));
  };

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), 'PPP', { locale: es });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-5xl max-h-[90vh] overflow-hidden">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Detalles de la Evaluación</h2>
          <div className="flex items-center space-x-2">
            <PDFDownloadLink
              document={<PDFDocument evaluation={evaluation} />}
              fileName={`evaluacion-${evaluation.processName.toLowerCase().replace(/\s+/g, '-')}.pdf`}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              {({ loading }) => (
                <>
                  <FileDown className="h-5 w-5 mr-2" />
                  {loading ? 'Generando PDF...' : 'Descargar PDF'}
                </>
              )}
            </PDFDownloadLink>
            <button
              onClick={onClose}
              className="p-2 text-gray-500 hover:text-gray-700"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="overflow-y-auto p-6">
          <div className="grid grid-cols-2 gap-6 mb-8">
            <div className="space-y-4">
              <div className="flex items-center">
                <User className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <p className="text-sm text-gray-500">Evaluador</p>
                  <p className="font-medium">{evaluation.assessmentInfo.evaluatorName}</p>
                </div>
              </div>
              <div className="flex items-center">
                <Calendar className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <p className="text-sm text-gray-500">Fecha</p>
                  <p className="font-medium">{evaluation.assessmentInfo.date}</p>
                </div>
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex items-center">
                <MapPin className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <p className="text-sm text-gray-500">Ubicación</p>
                  <p className="font-medium">{evaluation.assessmentInfo.location}</p>
                </div>
              </div>
              <div className="flex items-center">
                <Building className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <p className="text-sm text-gray-500">Área</p>
                  <p className="font-medium">{evaluation.assessmentInfo.area}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-6">
            {evaluation.stages.map((stage) => (
              <div key={stage.id} className="border rounded-lg">
                <button
                  onClick={() => toggleStage(stage.id)}
                  className="w-full px-4 py-3 flex items-center justify-between text-left hover:bg-gray-50"
                >
                  <div>
                    <h3 className="font-medium text-lg">{stage.name}</h3>
                    <p className="text-sm text-gray-500">{stage.description}</p>
                  </div>
                  {expandedStages[stage.id] ? (
                    <ChevronUp className="h-5 w-5 text-gray-400" />
                  ) : (
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                  )}
                </button>

                {expandedStages[stage.id] && (
                  <div className="px-4 pb-4">
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div>
                        <h4 className="text-sm font-medium text-gray-700 mb-2">
                          Entendimiento del Empleado
                        </h4>
                        <p className="text-sm text-gray-600">{stage.employeeUnderstanding}</p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-700 mb-2">
                          Procedimiento Formal
                        </h4>
                        <p className="text-sm text-gray-600">{stage.formalProcedure}</p>
                      </div>
                    </div>

                    {stage.gapAnalysis && (
                      <div className="mt-4">
                        <h4 className="font-medium text-gray-700 mb-2">
                          Análisis de Brechas
                        </h4>
                        <GapAnalysis
                          gaps={stage.gapAnalysis}
                          loading={false}
                          error={null}
                        />
                      </div>
                    )}

                    <div className="mt-4">
                      <h4 className="font-medium text-gray-700 mb-2">
                        Evaluación de Pilares
                      </h4>
                      <div className="space-y-3">
                        {Object.entries(evaluation.pillars[stage.id] || {}).map(([pillar, assessment]) => (
                          <div key={pillar} className="flex items-center justify-between p-2 bg-gray-50 rounded">
                            <span className="text-sm font-medium">{pillar}</span>
                            <div className="flex items-center">
                              {assessment.rating === 'efectivo' ? (
                                <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
                              ) : (
                                <XCircle className="h-5 w-5 text-red-500 mr-2" />
                              )}
                              <span className="text-sm">
                                {assessment.rating === 'efectivo' ? 'Efectivo' : 'No efectivo'}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}