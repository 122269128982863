import React, { useState } from 'react';
import { ClipboardList, Search, Plus, BookTemplate, Trash2 } from 'lucide-react';
import { useAssessmentStore } from '../store/assessmentStore';
import { processTemplates } from '../data/processTemplates';
import ProcessForm from './ProcessForm';
import DeleteConfirmDialog from './DeleteConfirmDialog';

interface DeleteConfirmState {
  isOpen: boolean;
  processId: string;
  processName: string;
}

export default function ProcessSelection() {
  const { processes, setCurrentProcess, useTemplate, deleteProcess } = useAssessmentStore();
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showTemplates, setShowTemplates] = useState(true);
  const [deleteConfirm, setDeleteConfirm] = useState<DeleteConfirmState>({
    isOpen: false,
    processId: '',
    processName: '',
  });

  const filteredItems = showTemplates
    ? processTemplates.filter((template) =>
        template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        template.description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : processes.filter((process) =>
        process.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        process.description.toLowerCase().includes(searchTerm.toLowerCase())
      );

  const handleDeleteClick = (e: React.MouseEvent, processId: string, processName: string) => {
    e.stopPropagation();
    setDeleteConfirm({
      isOpen: true,
      processId,
      processName,
    });
  };

  const handleConfirmDelete = () => {
    deleteProcess(deleteConfirm.processId);
    setDeleteConfirm({
      isOpen: false,
      processId: '',
      processName: '',
    });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <h2 className="text-2xl font-bold text-gray-900">
                {showTemplates ? 'Plantillas de Procesos' : 'Tus Procesos'}
              </h2>
              <button
                onClick={() => setShowTemplates(!showTemplates)}
                className="text-sm text-blue-600 hover:text-blue-700"
              >
                {showTemplates ? 'Ver Tus Procesos' : 'Ver Plantillas'}
              </button>
            </div>
            {!showTemplates && (
              <button
                onClick={() => setShowForm(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                <Plus className="h-5 w-5 mr-2" />
                Nuevo Proceso
              </button>
            )}
          </div>

          <div className="relative mb-6">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar procesos..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {filteredItems.length === 0 ? (
              <div className="col-span-full p-8 text-center">
                <ClipboardList className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">No se encontraron procesos</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {searchTerm ? 'Intenta con otros términos de búsqueda' : 'Comienza creando un nuevo proceso'}
                </p>
              </div>
            ) : (
              filteredItems.map((item) => (
                <div
                  key={item.id}
                  className="border rounded-lg p-4 hover:shadow-md transition-shadow"
                >
                  <div className="flex justify-between items-start mb-2">
                    <h3 className="font-semibold text-lg">{item.name}</h3>
                    <div className="flex space-x-2">
                      {showTemplates ? (
                        <button
                          onClick={() => useTemplate(item.id)}
                          className="text-blue-600 hover:text-blue-700"
                        >
                          <BookTemplate className="h-5 w-5" />
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() => setCurrentProcess(item)}
                            className="text-sm text-blue-600 hover:text-blue-700"
                          >
                            Seleccionar
                          </button>
                          <button
                            onClick={(e) => handleDeleteClick(e, item.id, item.name)}
                            className="text-red-500 hover:text-red-600"
                          >
                            <Trash2 className="h-5 w-5" />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <p className="text-sm text-gray-600">{item.description}</p>
                  {!showTemplates && (
                    <div className="mt-4 flex items-center text-sm text-gray-500">
                      <span>{item.stages.length} etapas</span>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {showForm && <ProcessForm onClose={() => setShowForm(false)} />}
      
      <DeleteConfirmDialog
        isOpen={deleteConfirm.isOpen}
        onClose={() => setDeleteConfirm({ isOpen: false, processId: '', processName: '' })}
        onConfirm={handleConfirmDelete}
        processName={deleteConfirm.processName}
      />
    </div>
  );
}