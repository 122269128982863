import React from 'react';
import { motion } from 'framer-motion';
import { 
  ClipboardCheck, 
  Shield, 
  TrendingUp, 
  Brain,
  ChevronRight,
  BarChart3,
  Users,
  Target,
  BookOpen,
  CheckSquare,
  FileSearch,
  ExternalLink
} from 'lucide-react';

interface LandingPageProps {
  onStart: () => void;
}

export default function LandingPage({ onStart }: LandingPageProps) {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#3583d7] to-primary-900">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <motion.div 
            className="flex-1 text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className="text-5xl lg:text-6xl font-bold mb-6">
              OD+
              <span className="block text-primary-300">Herramienta de Evaluación de Disciplina Operativa</span>
            </h1>
            <p className="text-xl text-primary-100 mb-8 font-light">
              Mantener el sentido de vulnerabilidad requiere disciplina
            </p>
            <p className="text-lg text-primary-200 mb-8">
              Plataforma integral que combina diagnósticos organizacionales precisos con formación especializada 
              para consultores y líderes en seguridad industrial, enfocada en Disciplina Operativa
            </p>
            <div className="flex flex-wrap gap-4">
              <motion.button
                onClick={onStart}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="btn-primary text-lg px-8 py-3 rounded-full bg-[#3583d7] hover:bg-[#2d70bc]"
              >
                Diagnosticar DO
                <ChevronRight className="ml-2 h-5 w-5" />
              </motion.button>
              <motion.a
                href="https://www.wfs.solutions/do"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="btn-secondary text-lg px-8 py-3 rounded-full inline-flex items-center"
              >
                Curso Disciplina Operativa
                <ExternalLink className="ml-2 h-5 w-5" />
              </motion.a>
            </div>
          </motion.div>

          <motion.div 
            className="flex-1"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <div className="relative">
              <div className="absolute -inset-1 bg-gradient-to-r from-[#3583d7] to-accent-light rounded-lg blur opacity-30"></div>
              <div className="relative bg-primary-800 rounded-lg p-6 shadow-xl">
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-primary-700 rounded-lg p-4">
                    <BarChart3 className="h-6 w-6 text-[#3583d7] mb-2" />
                    <h3 className="text-primary-100 font-medium">Diagnósticos</h3>
                    <p className="text-3xl font-bold text-white">124</p>
                  </div>
                  <div className="bg-primary-700 rounded-lg p-4">
                    <Users className="h-6 w-6 text-[#3583d7] mb-2" />
                    <h3 className="text-primary-100 font-medium">Equipos</h3>
                    <p className="text-3xl font-bold text-white">37</p>
                  </div>
                </div>
                <div className="mt-4 bg-primary-700 rounded-lg p-4">
                  <Target className="h-6 w-6 text-[#3583d7] mb-2" />
                  <h3 className="text-primary-100 font-medium">Objetivos Alcanzados</h3>
                  <div className="h-2 bg-primary-600 rounded-full mt-2">
                    <div className="h-2 bg-[#3583d7] rounded-full" style={{ width: '75%' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-primary-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-3xl font-bold text-primary-900 mb-4">
              Características Principales
            </h2>
            <p className="text-lg text-primary-600">
              Herramientas especializadas para la Disciplina Operativa
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="bg-white rounded-xl p-6 shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <div className="bg-primary-100 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                  <feature.icon className="h-6 w-6 text-[#3583d7]" />
                </div>
                <h3 className="text-xl font-semibold text-primary-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-primary-600">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-primary-900 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-3xl font-bold mb-4">
              Haz que tu Disciplina Operativa sea tangible, medible y mejorable
            </h2>
            <p className="text-xl text-primary-200 mb-8">
              Únete a las organizaciones que ya están mejorando su cultura de seguridad
            </p>
            <motion.a
              href="https://www.wfs.solutions/do"
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="btn-primary text-lg px-8 py-3 rounded-full bg-[#3583d7] hover:bg-[#2d70bc] inline-flex items-center"
            >
              Toma del Curso de Disciplina Operativa
              <ExternalLink className="ml-2 h-5 w-5" />
            </motion.a>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

const features = [
  {
    icon: FileSearch,
    title: 'Evaluación de Procesos',
    description: 'Diagnóstico profundo de la adherencia a procedimientos y comprensión de los empleados.'
  },
  {
    icon: Shield,
    title: 'Análisis de Brechas',
    description: 'Identificación precisa de discrepancias entre procedimientos formales y ejecución real.'
  },
  {
    icon: BookOpen,
    title: 'Capacitación DO',
    description: 'Programa estructurado de formación en los principios fundamentales de Disciplina Operativa.'
  },
  {
    icon: Brain,
    title: 'Evaluación de Pilares',
    description: 'Medición sistemática de Formalidad, Competencia, Trabajo en Equipo, Confianza y Actitud Cuestionadora.'
  },
  {
    icon: CheckSquare,
    title: 'Seguimiento de Mejoras',
    description: 'Monitoreo continuo del progreso en la implementación de Disciplina Operativa.'
  },
  {
    icon: TrendingUp,
    title: 'Métricas de Desempeño',
    description: 'Indicadores clave para medir la efectividad de la Disciplina Operativa en tu organización.'
  }
];