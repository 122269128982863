import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCkoKeVzfcf44wIJzwEbEi7f-th3EQdvBQ",
  authDomain: "odplus-b1bbe.firebaseapp.com",
  projectId: "odplus-b1bbe",
  storageBucket: "odplus-b1bbe.firebasestorage.app",
  messagingSenderId: "93627911248",
  appId: "1:93627911248:web:ba3aabd11d5d3bfc325555",
  measurementId: "G-6MZE184WC4"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);