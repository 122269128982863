import React from 'react';
import { PillarRating, StagePillars } from '../types/assessment';
import { HelpCircle, CheckCircle2, XCircle } from 'lucide-react';

type PillarAssessmentProps = {
  stagePillars: StagePillars;
  onUpdate: (pillar: keyof StagePillars, rating: PillarRating, observations: string) => void;
};

const pillarCriteria = {
  humanIntegrity: {
    efectivo: "Consistencia en realizar actividades según lo comprometido (siempre en línea con la formalidad y el Nivel de Conocimiento)",
    noEfectivo: "Inconsistencias entre lo dicho y lo hecho (Modo excusa todo el tiempo). Ejemplos: Ese procedimiento nunca sirvió de nada, esa práctica no refleja la realidad."
  },
  formalityDeficiency: {
    efectivo: "Lo que se ejecuta se alinea a lo Imaginado [escrito]",
    noEfectivo: "La ejecución no se alinea a lo imaginado [escrito y formal] y no existe ningún ejercicio de identificación de brechas de DO."
  },
  teamworkDeficiency: {
    efectivo: "Colaboración fluida sin barreras organizacionales ni declaraciones de \"esto no es mi trabajo\"",
    noEfectivo: "\"Esto no es mi trabajo\" inhibe colaboración"
  },
  competencyDeficiency: {
    efectivo: "Competencia asegurada con observaciones regulares de tareas (Task Observations)",
    noEfectivo: "No existe evidencia de aseguramiento de Competencias"
  },
  questioningAttitudeDeficiency: {
    efectivo: "Promoción activa del cuestionamiento sin miedo a represalias",
    noEfectivo: "Los individuos se abstienen de cuestionar por temor a represalias"
  }
};

const pillarNames = {
  humanIntegrity: 'Integridad Humana (Trust)',
  formalityDeficiency: 'Formalidad',
  teamworkDeficiency: 'Trabajo en Equipo',
  competencyDeficiency: 'Nivel de Conocimiento (Asegurar Competencias)',
  questioningAttitudeDeficiency: 'Actitud de Cuestionamiento',
};

const ratingOptions: PillarRating[] = ['efectivo', 'no-efectivo'];

export default function PillarAssessment({ stagePillars, onUpdate }: PillarAssessmentProps) {
  return (
    <div className="space-y-6">
      {(Object.keys(pillarNames) as Array<keyof typeof pillarNames>).map((pillar) => (
        <div key={pillar} className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-start justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">{pillarNames[pillar]}</h3>
            <button
              type="button"
              className="group relative"
              aria-label="Ver criterios de efectividad"
            >
              <HelpCircle className="h-5 w-5 text-gray-400 hover:text-gray-600" />
              <div className="invisible group-hover:visible absolute right-0 w-80 p-4 mt-2 bg-white rounded-lg shadow-lg border border-gray-200 z-10">
                <div className="space-y-3">
                  <div>
                    <h4 className="font-medium text-sm text-green-600 mb-1">Efectivo:</h4>
                    <p className="text-sm text-gray-600">{pillarCriteria[pillar].efectivo}</p>
                  </div>
                  <div>
                    <h4 className="font-medium text-sm text-red-600 mb-1">No Efectivo:</h4>
                    <p className="text-sm text-gray-600">{pillarCriteria[pillar].noEfectivo}</p>
                  </div>
                </div>
              </div>
            </button>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Efectividad</label>
              <div className="flex space-x-6">
                {ratingOptions.map((rating) => {
                  const isSelected = stagePillars[pillar]?.rating === rating;
                  const isEffective = rating === 'efectivo';
                  
                  return (
                    <label
                      key={rating}
                      className={`
                        relative inline-flex items-center px-4 py-2 rounded-md cursor-pointer
                        ${isSelected
                          ? isEffective
                            ? 'bg-green-50 border-green-200 text-green-700'
                            : 'bg-red-50 border-red-200 text-red-700'
                          : 'bg-white border-gray-200 text-gray-700 hover:bg-gray-50'
                        }
                        border transition-colors duration-200
                      `}
                    >
                      <input
                        type="radio"
                        name={`rating-${pillar}`}
                        value={rating}
                        checked={isSelected}
                        onChange={(e) => onUpdate(pillar, e.target.value as PillarRating, stagePillars[pillar]?.observations || '')}
                        className="sr-only"
                      />
                      <div className="flex items-center">
                        {isSelected && (
                          isEffective
                            ? <CheckCircle2 className="h-4 w-4 mr-2 text-green-500" />
                            : <XCircle className="h-4 w-4 mr-2 text-red-500" />
                        )}
                        <span className="capitalize">
                          {rating === 'efectivo' ? 'Efectivo' : 'No efectivo'}
                        </span>
                      </div>
                    </label>
                  );
                })}
              </div>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Observaciones</label>
              <textarea
                value={stagePillars[pillar]?.observations || ''}
                onChange={(e) => onUpdate(pillar, stagePillars[pillar]?.rating || 'efectivo', e.target.value)}
                rows={3}
                className={`
                  mt-1 block w-full rounded-md shadow-sm focus:ring-2 focus:ring-opacity-50
                  ${stagePillars[pillar]?.rating === 'efectivo'
                    ? 'border-green-200 focus:border-green-300 focus:ring-green-200'
                    : stagePillars[pillar]?.rating === 'no-efectivo'
                    ? 'border-red-200 focus:border-red-300 focus:ring-red-200'
                    : 'border-gray-300 focus:border-blue-300 focus:ring-blue-200'
                  }
                `}
                placeholder="Ingrese sus observaciones..."
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}