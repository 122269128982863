import React, { useState } from 'react';
import { useAssessmentStore } from '../store/assessmentStore';
import { SavedEvaluation } from '../types/assessment';
import { CheckCircle2, XCircle, AlertCircle, Calendar, MapPin, User, ChevronRight, Copy } from 'lucide-react';
import EvaluationDetail from './EvaluationDetail';

const pillarNames = {
  humanIntegrity: 'Integridad Humana',
  formalityDeficiency: 'Formalidad',
  teamworkDeficiency: 'Trabajo en Equipo',
  competencyDeficiency: 'Competencia',
  questioningAttitudeDeficiency: 'Actitud Cuestionadora',
};

export default function HistoricalEvaluations() {
  const { savedEvaluations, cloneEvaluation } = useAssessmentStore();
  const [selectedEvaluation, setSelectedEvaluation] = useState<SavedEvaluation | null>(null);

  if (savedEvaluations.length === 0) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center py-12 bg-white rounded-lg shadow-sm">
          <AlertCircle className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-lg font-medium text-gray-900">No hay evaluaciones guardadas</h3>
          <p className="mt-1 text-gray-500">Las evaluaciones completadas aparecerán aquí</p>
        </div>
      </div>
    );
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleClone = (e: React.MouseEvent, evaluationId: string) => {
    e.stopPropagation();
    cloneEvaluation(evaluationId);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">Historial de Evaluaciones</h2>
      <div className="space-y-6">
        {savedEvaluations.map((evaluation: SavedEvaluation) => (
          <div
            key={evaluation.id}
            className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden hover:shadow-md transition-shadow"
          >
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <div className="flex-1 cursor-pointer" onClick={() => setSelectedEvaluation(evaluation)}>
                  <h3 className="text-xl font-semibold text-gray-900">{evaluation.processName}</h3>
                  <div className="flex items-center text-gray-500 mt-1">
                    <span className="text-sm">{formatDate(evaluation.completedAt)}</span>
                    <ChevronRight className="h-5 w-5 ml-2" />
                  </div>
                </div>
                <button
                  onClick={(e) => handleClone(e, evaluation.id)}
                  className="ml-4 inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  title="Usar como plantilla"
                >
                  <Copy className="h-4 w-4 mr-2" />
                  Clonar
                </button>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                <div className="flex items-center text-gray-600">
                  <User className="h-5 w-5 mr-2" />
                  <span>{evaluation.assessmentInfo.evaluatorName}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <MapPin className="h-5 w-5 mr-2" />
                  <span>{evaluation.assessmentInfo.location}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <Calendar className="h-5 w-5 mr-2" />
                  <span>{evaluation.assessmentInfo.date}</span>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <span className="text-sm text-gray-500">
                  {evaluation.stages.length} etapas evaluadas
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedEvaluation && (
        <EvaluationDetail
          evaluation={selectedEvaluation}
          onClose={() => setSelectedEvaluation(null)}
        />
      )}
    </div>
  );
}